import { template as template_a4860c291a26494288ec1af75d578377 } from "@ember/template-compiler";
const FKText = template_a4860c291a26494288ec1af75d578377(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
