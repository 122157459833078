import { template as template_0cce5f5646de471f97cfcb32698aecc5 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_0cce5f5646de471f97cfcb32698aecc5(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
