import { template as template_4748ce2031c24d65bc0565c146a10d22 } from "@ember/template-compiler";
const WelcomeHeader = template_4748ce2031c24d65bc0565c146a10d22(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
