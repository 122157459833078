import { template as template_ac197d4765484bb9a9b499abf0f8594a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_ac197d4765484bb9a9b499abf0f8594a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
