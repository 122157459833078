import { template as template_cca07eb5d3554f8f9a29c5d911ddb9a6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_cca07eb5d3554f8f9a29c5d911ddb9a6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
