import { template as template_581201613d1447d1ad15784791f8b202 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_581201613d1447d1ad15784791f8b202(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
